import React from 'react';
import { EmailOutlined, LinkedIn, GitHub} from '@mui/icons-material';

const Profile = () => {
  return (
    <div>
      <div className='profile-container' id='profile'>
        <div className='profile container '>
          <img
            className='profile-photoID'
            alt='photoID'
            src='/imgs/AI.png'
          ></img>
          <div className='profile-text'>
            <h1>Rachael Ify Okedo</h1>
            <h2>Frontend Engineer</h2>
            <div className='profile-socials'>
              <a
                href='mailto:okedo.ifeoma@gmail.com'
                target='_blank'
                rel='noreferrer'
              >
                <EmailOutlined />
              </a>
              <a
                href='https://www.linkedin.com/in/ify-okedo/'
                target='_blank'
                rel='noreferrer'
              >
                <LinkedIn />
              </a>
              <a
                href='https://github.com/kaydo1506'
                target='_blank'
                className='btn'
                rel='noreferrer'
              >
                <GitHub />
              </a>
          
            </div>
            <p>
              <br /> Hello! I am a problem-solving software engineer and I build
              things for the web using React. I am Proficient in designing and
              developing responsive and engaging web applications, API
              integration, troubleshooting complex, and simple problems, and
              implementing new features based on user feedback, with a proven
              track record.
              I consider myself creative and hardworking. If you like my profile, drop me a message and we'll discuss
              how I can be useful to your project.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
