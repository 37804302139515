import React from 'react';

const Education = () => {
    return (
        <div className='education-container'>
            <div className='container edu'>
                <div className='edu1'>
                    <h1>Education</h1>
                    <ul>
                        <li>Bachelor of Engineering in Chemical Engineering (2011-2016 // Nigeria)</li>
                        <li>Bachelor of Science in Computer Science (2019-2022 // Benin)</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Education;
