import React from 'react';

const Experience = () => {
  return (
    <div className='experience-container' id='experience'>
      <div className='container '>
        <h1>Experience</h1>

        <div className='col '>
          <div className='col1'>
            <h3>Frontend Engineer</h3>
          </div>

          <ul className='col2'>
            <li>
              Delivered high-quality and responsive code into production using
              modern libraries and frameworks
            </li>
            <li>Rest API integration</li>
            <li>
              Leading proactive system monitoring initiatives using advanced
              tools such as Sentry and Redash, fostering early detection and
              swift resolution of potential issues
            </li>
            <li>
              Collaborating closely with development teams to troubleshoot a
              range of technical issues, leveraging my frontend skills to
              enhance user interfaces and improve product functionality
            </li>
            <li>
              Designed and implemented web applications along with 3rd-party
              software integrations as a web team liaison for all
              inter-departmental and customer-facing projects
            </li>
            <li>Tested React components using Jest</li>
            <li>
              Incorporating Next.js incremental static regeneration, and
              managing database development
            </li>
            <li>
              Consulted for startups and refactored code to implement latest
              technologies for optimisation and general web presentation, while
              also ensuring browser compatibility
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Experience;
