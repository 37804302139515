import React from 'react';

const SkillsQualities = () => {
  return (
    <div className='skillsQualities-container'>
      <div className='container wrap'>
        <div className='skills'>
          <h1 className='skills-text'>Skills</h1>
          <p className='skills-list'>
            HTML/CSS, CSS Framework (Bootstrap, Tailwind, Sass), JavaScript/TypeScript, Responsive web design, React/NextJs (Hooks & Redux), Test-Driven Development (Jest, react testing library), Figma, Version control.
          </p>
        </div>
        <div className='qualities'>
          <h1 className='qualities-text'>Qualities</h1>
          <p className='qualities-list'>
            Self-motivated, Creative, Adaptable, Having an eye for detail.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SkillsQualities;
